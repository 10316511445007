import React from "react";
import { AboutUsHeader, RequestDemo, Vision } from "../components";
import { Helmet } from "react-helmet";
const AboutUs = () => {
  return (
    <div>
      <Helmet>
        <title>About Us | Origin Business Solutions</title>
      </Helmet>
      <AboutUsHeader />
      <Vision />
      <RequestDemo />
    </div>
  );
};

export default AboutUs;
