import React from "react";
import WebDevelopmentCard from "../cards/WebDevelopmentCard";
import application from "../../assets/application 1.png";
import cms from "../../assets/cms 1.png";
import feature from "../../assets/feature 1.png";
import onlineStore from "../../assets/online-store 1.png";
import tablet from "../../assets/tablet-and-laptop 1.png";
import prototyping from "../../assets/UI/prototyping 1.png";

const WebDevelopmentCards = () => {
  return (
    <div className="cards-container">
      <WebDevelopmentCard
        imgSrc={tablet}
        title="Responsive Websites"
        desc="A website with a responsive design, on the other hand, automatically resizes all its content according to the device in use, be it a smartphone, a tablet, a laptop, or a desktop. Responsive websites generate higher conversions across different types of devices. All the projects our website development team works on are completely responsive, sure that website will work perfectly well on any device."
      />

      <WebDevelopmentCard
        imgSrc={onlineStore}
        title="E-Commerce Development "
        desc="While offering a secure shopping experience is crucial, our focus is also on providing a great user experience. We make sure consumers can find and select products easily. Some of the aspects of e-commerce development we cover include inventory, payment gateways, pricing models, product variations, dynamic shipping and tracking, etc."
      />

      <WebDevelopmentCard
        imgSrc={cms}
        title="Content Management System"
        desc="A reliable content management system (CMS) gives you the ability to store, organize, and publish different kinds of content through a central interface. The custom CMS platforms we design take into account your business goals and its specific website development needs."
      />

      <WebDevelopmentCard
        imgSrc={feature}
        title="Custom Web Development"
        desc="We offer customized website development solutions, ensuring that every project we work on is truly unique. We do not make use of templates, and work in developing each new website from scratch. From front-end elements that users interact with to back-end programming that comprises of complicated code, we leave no stone unturned in delivering a user-friendly customized website."
      />

      <WebDevelopmentCard
        imgSrc={application}
        title="Web Application"
        desc="Any application that relies on a web browser to run is a web application. Examples of web applications that your website may need include guest sign-ins, message boards, word processors, and spreadsheets. Our website development team has more than enough experience in building B2B and B2C web applications for real-time communication, data analytics, video delivery, and e-learning."
      />

      <WebDevelopmentCard
        imgSrc={prototyping}
        title="UI/UX and Prototyping"
        desc="We offer throughout the entire UI/UX design process, collaboration between designers, developers, stakeholders, and end-users is essential to ensure that the website meets user needs, aligns with business goals, and delivers a positive and engaging user experience."
      />
    </div>
  );
};

export default WebDevelopmentCards;
