// import JobPostCard from "../cards/JobPostCard";
import "./index.css";
import noJobPost from "../../assets/NoJobPost.png";
export default function JobPosts() {
  // const jobs = [
  //   { position: "Project Coordinator", isUrgent: true },
  //   { position: "Frontend Developer", isUrgent: false },
  //   { position: "Backend Developer", isUrgent: false },
  //   { position: "Sales and Marketing Manager", isUrgent: true },
  //   { position: "Product Owner", isUrgent: false },
  //   { position: "UI/UX Designer", isUrgent: false },
  // ];

  return (
    // <div className="flex flex-wrap justify-evenly">
    //   {jobs.map((job, index) => (
    //     <JobPostCard
    //       key={index}
    //       position={job.position}
    //       urgent={job.isUrgent}
    //     />
    //   ))}
    // </div>
    <div className="justify-center flex align-middle m-40">
      <img src={noJobPost} alt="No Jobs Available For Now" />
    </div>
  );
}
