import React from "react";
import "./index.css";
import SectionHeader from "../cards/Header";

const WebDevelopmentHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Web Development"
        info="Website development refers to the process of creating, designing, and maintaining websites. It involves various tasks, including web design, web programming, content creation, and web server configuration, to build functional and visually appealing websites. "
      />
    </div>
  );
};

export default WebDevelopmentHeader;
