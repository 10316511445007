export const SiteMapCard = ({ title, className, img }) => {
  return (
    <div
      className={`${className} shadow-lg border-2 m-5 hover:shadow-2xl flex flex-col min-w-52 min-h-52 p-5 site-map-card`}>
      <img src={img} alt={title} className="site-map-card-image" />
      <h3 className="text-center font-bold min-h-10 w-auto mt-5 text-sm">
        {title}
      </h3>
    </div>
  );
};
