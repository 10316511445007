import React from "react";
import SectionHeader from "../cards/Header";
import { five, four, one, six, three, two } from "../../assets/number/index";
import SolutionCard from "../cards/SolutionCard";
import "./index.css";
const SolutionsHeader = () => {
  const businesseSolutions = [
    {
      number: one,
      title: "Retail/Wholesale Manufacturing Sector",
      short: false,
      products: [
        "Ecommerce B2B, B2C (Web)",
        "Ecommerce B2B, B2C Mobile App - Android, iOS",
        "Integration (Payment Gateway, SMS Gateway, Delivery, Inventory)",
      ],
    },
    {
      number: two,
      short: false,
      title: "Education Sector",
      products: [
        "School Management System",
        "Learning Management System",
        "Exam Management System",
        "Online Admission System",
      ],
    },
    {
      number: three,
      short: false,
      title: "Real Estate and Construction",
      products: [
        "Condo Management System",
        "Apartment Management System",
        "Tenant Management System",
      ],
    },
    {
      number: four,
      short: true,
      title: "Hotel and Tourism Sector",
      products: [
        "Hotel Management System",
        "Hotel Booking System",
        "Membership System",
      ],
    },
    {
      number: five,
      short: true,
      title: "Healthcare Sector",
      products: ["Clinic Management System", "Online Booking System"],
    },
    {
      number: six,
      short: true,
      title: "Customer Services",
      products: ["Membership & Reward System", "Online Reservation System"],
    },
  ];

  return (
    <div>
      <SectionHeader title="OBS Solutions" info="" />
      <h3>Digital Transformation Core Business</h3>
      <div className="flex w-full  flex-wrap justify-evenly mb-10">
        {businesseSolutions.map((solution, id) => (
          <div key={id}>
            <SolutionCard
              number={solution.number}
              title={solution.title}
              info={solution.products}
              short={solution.short}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SolutionsHeader;
