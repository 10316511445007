import React from 'react'
import SectionHeader from '../cards/Header'

const OutsourcingHeader = () => {
  return (
    <div>
      <SectionHeader title="Outsourced Software Development" info="Developing software tailored to your unique business requirements" />
    </div>
  )
}

export default OutsourcingHeader