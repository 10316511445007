import React from 'react'
import WhyWithUsImg from "../../assets/whyWithUs.png"

const WhyWithUs = () => {
  return (
    <div className='why-with-us-container'>
      <h3>Why partner with us and fully outsource your software development?</h3>
      <p>Outsourcing your software development is great for getting a discrete project done with minimal distraction to other teams and handling peaks in workload.</p>
      <img src={WhyWithUsImg} alt='why-with-us' />
    </div>
  )
}

export default WhyWithUs