import React from 'react'
import "./index.css"

const ManagementSystem = () => {
  return (
    <div className='management-container'>
      <h3 className='management-header'>Tenant-Property Management System</h3>
      <p className='management-desc'>Origin Property-Tenant Management System is a property management software solution for business sectors which is initially created  to small, mid and large enterprise real estate and shopping mall business developed by Origin Business Solutions Co.,Ltd. OPTMS is a specialized software solution designed to streamline and automate various aspects of property management, particularly in managing tenant-related tasks such as Residential Property management, Condo management, Real Estate Management, Shopping Mall Management and Tenant Management, etc.</p>
      <div className='management-lists'>
        <div className='managment-name'>Real Estate Management</div>
        <div className='managment-name'>Shopping Mall Management</div>
        <div className='managment-name'>Residence Management</div>
        <div className='managment-name'>Housing Management</div>
        <div className='managment-name'>Condo Management</div>
        <div className='managment-name'> Tenant Management</div>
      </div>
    </div>
  )
}

export default ManagementSystem