import React from "react";
import "./index.css";

const AboutUsHeader = () => {
  return (
    <div className="about-us-header">
      <div className="about-us-info">
        <h3 className="text-left translate-y-5">About Our Company</h3>
        <p className="header-info">
          Accelerate For The Future with OBS's Digital Solutions
        </p>
        <p className="about-us-detail">
          Origin Business Solutions Company is specializing in software
          development and digital transformation services. Specializing in
          delivering cutting-edge technology solutions, the company empowers
          businesses to modernize their operations, enhance customer
          experiences, and drive sustainable growth. With a team of highly
          skilled developers, engineers, and digital strategists, Origin
          Business Solutions offers end-to-end services, including Software
          Development, Digital Product Development, Cloud Managed Service and IT
          consulting.
        </p>
        <p className="about-us-detail">
          OBS focuses on simplifying processes, boosting efficiency, and
          adopting scalable technology to support digital innovation for
          Startups, SMEs, Enterprise and Group of Companies. They deliver
          personalized solutions for each client to ensure an easy transition
          into the digital world.
        </p>
        <p className="about-us-detail">
          Our team of expert Developers, Engineers, and Digital strategists work
          to deliver solutions that not only meet today’s needs but also
          anticipate tomorrow’s challenges. We believe in the power of
          technology to transform businesses and are dedicated to helping our
          clients navigate the evolving digital landscape with confidence and
          success.
        </p>
        <p className="about-us-detail">
          Our core values—innovation, customer focus, and quality excellence.
          them to offer reliable and future-ready technology solutions,
          positioning OBS as a trusted partner for digital transformation. Our
          agile, client-focused approach ensures that we not only provide
          high-quality technology but also build long-lasting partnerships based
          on trust and collaboration.
        </p>
      </div>
    </div>
  );
};

export default AboutUsHeader;
