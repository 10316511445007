import React, { useState } from "react";
import "../webDevelopment/index.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import "../webDevelopment/index.css";
import { motion } from "framer-motion";
import { one, three, two } from "../../assets/number";
import "../../index.css";
const KeyFunctions = () => {
  const [selectedKey, setSelectedKey] = useState(null);
  const keys = [
    {
      number: one,
      title: "Key Features",
      lines: [
        "Space planning and management",
        "Rented / Leased area or Available area",
        "Assets and Facility Management",
        "Contract Management",
        "Sales, Billing and CRM",
        "Payables & Receivable Management",
        "Customer Service handling",
        "Security and Access Control",
        "Vehicles permission, In/Out Time Recording and Parking planning",
      ],
    },
    {
      number: two,
      title: "Key Functions",
      lines: [
        "Integrates other software solutions",
        "Generates Financial reports with receivable, payable, outstanding status.",
        "Automates billing processes and allows for multiple online payments with Payment Gateway",
        "Manage and maintain property, branches, units, resident, tenant and lease data",
        "Manage third party vendor and contracts with tenants",
        "Centralizes lease administration and facilities management",
        "Offers built-in dashboards for reporting and insights",
        "Automates notices, and reports",
        "Improves staff performance with its process features",
      ],
    },
    {
      number: three,
      title: "Key Components",
      lines: [
        "Tenant Profiles: Detailed records of tenant information, including personal details, contact information, lease agreements, and payment history.",
        "Lease Management: Tools to create, renew, and terminate leases. It includes features for generating lease documents, tracking lease expiration, and sending renewal reminders.",
        "Rent Collection: Automated invoicing, payment reminders, and secure online payment options to streamline the rent collection process and reduce late payments.",
        "Maintenance Management: A system for tenants to submit maintenance requests and for property managers to track, assign, and manage these requests efficiently.",
        "Communication: A platform for sending announcements, updates, and messages to tenants, ensuring clear and documented communication.",
        "Financial Reporting: Access to various financial reports, such as income statements and rent roll reports, to analyze the financial performance of properties.",
        "Vacancy Management: Tools to manage unit availability, schedule viewings, process applications, and handle move-ins and move-outs efficiently.",
        "Accounting Integration: Features to manage income and expenses, vendor payments, and bank reconciliations, often integrating with existing accounting software.",
        "User Permissions: Customizable roles and permissions to control access to sensitive information and system features.",
        "Mobile Access: A mobile friendly interface that allows property managers to access the system and perform tasks on the go",
        "Multi-Property Support: The ability to manage multiple properties from a single platform, making it suitable for landlords and property managers with diverse portfolios.",
      ],
    },
  ];

  return (
    <div className="key-functions m-5 pb-10">
      <div className="flex flex-row justify-center">
        {keys.map((keyMap, index) => (
          <button
            key={index}
            onClick={() => {
              !selectedKey ? setSelectedKey(keyMap) : setSelectedKey(null);
            }}
            className="w-full">
            <div
              className="p-2 m-5 rounded-xl hover:bg-gray-200 flex flex-row  function-button align-middle gradient"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                minWidth: "150px",
              }}>
              <div className="flex flex-row align-middle">
                <img src={keyMap.number} alt="number-img" width={40} />
                <div className="mt-2 ml-2">{keyMap.title}</div>
              </div>
              <div className="mr-3 ml-auto mt-1">
                <FaArrowRight size={35} />
              </div>
            </div>
          </button>
        ))}
      </div>
      <div>
        {selectedKey && selectedKey.lines && (
          <motion.div
            initial={{ y: 100, opacity: 0 }}
            whileInView={{ opacity: 1, y: 0, scale: 1 }}
            transition={{ duration: 0.8, ease: "easeInOut" }}
            exit={{ opacity: 0, y: 100, scale: 0.5 }}
            viewport={{ once: false, amount: 0.5 }}
            className="selected-key-content">
            <div className="flex flex-row justify-between">
              <div className="flex flex-col">
                <FaArrowLeft
                  size={30}
                  onClick={() => {
                    setSelectedKey(null);
                  }}
                  className="mb-5"
                />{" "}
                <h1 className="gradient-number w-min-1/2 text-3xl mb-4 p-5 pr-9 rounded-lg">
                  Origin Property - Tenant {selectedKey.title}
                </h1>
              </div>

              <img src={selectedKey.number} alt="number-key" width={150} />
            </div>
            <ul>
              {selectedKey.lines.map((line, index) => (
                <li key={index}>{line}</li>
              ))}
            </ul>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default KeyFunctions;
