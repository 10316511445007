import React from "react";
import {
  Features,
  HeroHeader,
  LanguaguesWeUsed,
  OurProducts,
  OurService,
  PaymentSolution,
} from "../components";
import { Helmet } from "react-helmet";
const Hero = () => {
  return (
    <div id="hero">
      <Helmet>
        <title>Home | Origin Business Solutions</title>
      </Helmet>
      <HeroHeader />
      <Features />
      <OurService />
      <OurProducts />
      <PaymentSolution />
      <LanguaguesWeUsed />
    </div>
  );
};

export default Hero;
