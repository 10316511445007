import React from "react";
import {
  WebDevelopmentCards,
  WebDevelopmentHeader,
  WebDevelopmentJourney,
  WebsitePackagePlan,
} from "../components";
import { Helmet } from "react-helmet";
const WebDevelopment = () => {
  return (
    <div className="flex flex-col">
      <Helmet>
        <title>Website Development | Origin Business Solutions</title>
      </Helmet>
      <WebDevelopmentHeader />
      <WebDevelopmentCards />
      <WebDevelopmentJourney />
      <WebsitePackagePlan />
    </div>
  );
};

export default WebDevelopment;
