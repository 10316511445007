import React from "react";
import { PaymentBenefits, PaymentHeader, Solution } from "../components";
import { Helmet } from "react-helmet";
const Payment = () => {
  return (
    <div>
      <Helmet>
        <title>OBS Payment Solutions | Origin Business Solutions</title>
      </Helmet>
      <PaymentHeader />
      <Solution />
      <PaymentBenefits />
    </div>
  );
};

export default Payment;
