import React from "react";
import { motion } from "framer-motion";
import "./index.css";
import FeatureCard from "../cards/FeatureCard";
import SixYearExp from "../../assets/features/6 years exp.png";
import featureImage from "../../assets/features/feature-layout.png";
import first from "../../assets/HeroIcons/First.png";
import second from "../../assets/HeroIcons/Second.png";
import third from "../../assets/HeroIcons/Third.png";

const Feature = () => {
  const featureCards = [
    {
      title: "Cutting-Edge Innovation",
      info: "Continuously developing new and innovative software solutions to stay ahead of the competition and meet evolving customer needs.",
    },
    {
      title: "Agility",
      info: "Being able to adapt quickly to changes in technology, market trends, and customer preferences to remain competitive and seize new opportunities.",
    },
    {
      title: "Talent Development",
      info: "Investing in recruiting and retaining top talent, as well as providing ongoing training and development opportunities to ensure a skilled and motivated workforce.",
    },
  ];

  return (
    <>
      <div className="feature-header-container">
        {/* <p className="feature-title" style={{ textAlign: "center" }}>
          Features
        </p>
        <h3 style={{ textAlign: "center" }}>Our Strategic Values</h3> */}
        <img src={featureImage} alt="feature-header" className="w-full" />
        <p
          style={{ textAlign: "center" }}
          className="feature-info -translate-y-10">
          Our strategic value is by prioritizing to leverage technology
          innovation, agile methodologies and talent development which expertise
          to create products or services that address market needs effectively.
        </p>
      </div>

      <div className="flex justify-center flex-wrap -translate-y-10 gap-8 overflow-y-hidden  h-92">
        <motion.div
          className="feature-card-wrapper"
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={first}
            title={featureCards[0].title}
            info={featureCards[0].info}
          />
        </motion.div>
        <motion.div
          className="feature-card-wrapper -translate-y-6"
          initial={{ opacity: 0, x: -200, y: -5 }}
          whileInView={{ opacity: 1, x: -5 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={second}
            title={featureCards[1].title}
            info={featureCards[1].info}
          />
        </motion.div>
        <motion.div
          className="feature-card-wrapper"
          initial={{ opacity: 0, x: +200 }}
          whileInView={{ opacity: 1, x: 0, y: 12 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}>
          <FeatureCard
            number={third}
            title={featureCards[2].title}
            info={featureCards[2].info}
          />
        </motion.div>
      </div>

      <div className="six-year-exp-container">
        <motion.img
          initial={{ opacity: 0, y: 100, scale: 0.5 }}
          whileInView={{ opacity: 1, y: 0, scale: 1 }}
          transition={{ duration: 0.8, ease: "easeInOut" }}
          exit={{ opacity: 0, y: 100, scale: 0.5 }}
          viewport={{ once: false, amount: 0.5 }}
          height={350}
          width={350}
          className="feature-card-wrapper  cursor-pointer"
          src={SixYearExp}
          alt="six-year-exp"
        />
        <div className="six-year-exp-info">
          <h3>OVER 6 YEARS EXPERIENCE</h3>
          <div className="divider"></div>
          <p>
            Origin Business Solutions Company - OBS was established in 2018 and
            providing Digital Solutions providers in Myanmar.
          </p>
          <button className="explore-more-btn -translate-y-5">
            Contact Us
          </button>
        </div>
      </div>
    </>
  );
};

export default Feature;
