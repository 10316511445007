import React, { useState } from "react";
import "./index.css";
import LightingHeader from "../../assets/lighting_header.png";
import CardComponent from "../cards/CardComponent";
import SoftwareDevelopment from "../../assets/ourServices/software-development.png";
import WebDevelopment from "../../assets/ourServices/web-development.png";
import ApplicationDevelopment from "../../assets/ourServices/app-development.png";
import SoftwareDevelopmentOutsourcing from "../../assets/ourServices/software-development-outsourcing.png";
import CloudHosting from "../../assets/ourServices/cloud-hosting.png";
import { MdHexagon } from "react-icons/md";
import { motion } from "framer-motion";
import { Carousel } from "antd";

const OurService = () => {
  const [activeService, setActiveService] = useState(null);

  const services = [
    {
      imgSrc: WebDevelopment,
      title: "Website Development",
      bracket: " ",
      description:
        "Website development refers to the process of creating, designing, and maintaining websites. It involves various tasks, including web design, web programming, content creation, and web server configuration, to build functional and visually appealing websites.",
      productLists: [
        "Responsive Websites",
        "E-Commerce Development",
        "Content Management System (CMS)",
        "Custom Web Development",
        "Web Application",
        "UI/UX and Prototyping",
      ],
    },
    {
      imgSrc: ApplicationDevelopment,
      title: "Application Development",
      bracket: "(Customized - IOS, Android)",
      description:
        "Application development can be tailored to various platforms and technologies, including web applications, mobile applications (iOS, Android), desktop applications, and hybrid or cross-platform applications.\n\nCustomized application development refers to the process of creating software applications for specific purposes, such as business productivity, communication, entertainment, education, or gaming, etc.\n\nCustomized application development involves creating software solutions specifically tailored to meet the unique needs and requirements of a particular client or business.",
      productLists: null,
    },
    {
      imgSrc: SoftwareDevelopment,
      title: "Software Development",
      bracket: "Customized",
      description:
        "OBS enables delivery of software solutions that precisely match the requirements of their clients, enhancing operational efficiency, supporting strategic goals, and providing significant value.\n\nCustomized software development involves creating software solutions that are specially tailored to meet the unique needs and requirements of a particular client or business.\n\nUnlike off-the-shelf software, customized software is built to address specific challenges and workflows, providing a personalized solution that fits seamlessly into the client operations.",
      productLists: null,
    },
    {
      imgSrc: SoftwareDevelopmentOutsourcing,
      title: "Software Development (Outsourcing)",
      bracket: null,
      description:
        "OBS - Outsourced software development is a strategic approach that allows organizations to leverage external expertise and resources, enabling cost-effective and efficient software development while focusing on their core business activities.",
      productLists: [
        "Web Development",
        "Mobile App Development",
        "Custom Software Solutions",
      ],
    },
    {
      imgSrc: CloudHosting,
      title: "Cloud Hosting & Managed Service",
      bracket: null,
      description:
        "Offer faster and more reliable performance than traditional hosting as your website or application is hosted on multiple servers with load balancing and caching mechanisms in place to improve speed and reliability.",
      productLists: [
        "Secure Platform",
        "Advanced Analytics",
        "Powerful Automation",
      ],
    },
  ];

  return (
    <div className="our-services">
      <img
        className="lighting-header"
        src={LightingHeader}
        alt="lighting-header"
      />
      <h3 className="m-5">Our Services</h3>
      <div className="service-card-group text-white">
        {activeService ? (
          <div
            onClick={() => setActiveService(null)}
            className="flex flex-col sm:flex-row align-center justify-center pl-20 pr-20">
            <motion.div
              initial={{ opacity: 0, x: -100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              className="h-full">
              <CardComponent
                imgSrc={activeService.imgSrc}
                title={activeService.title}
              />
            </motion.div>
            <motion.div
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ duration: 1.5 }}
              className="m-5 h-full w-1/2">
              <h4>
                {activeService.title}
                {activeService.bracket}
              </h4>
              <p>{activeService.description}</p>
              {activeService.productLists && (
                <ul>
                  {activeService.productLists.map((product, index) => (
                    <li key={index} className="flex flex-row w-max-[500px]">
                      <MdHexagon /> {product}
                    </li>
                  ))}
                </ul>
              )}
            </motion.div>
          </div>
        ) : (
          <>
            <div className=" flex w-9/12 sm:hidden md:hidden lg:hidden xl:hidden">
              <Carousel>
                {services.map((service, index) => (
                  <div
                    key={index}
                    onClick={() => setActiveService(service)}
                    className="service-card">
                    <CardComponent
                      imgSrc={service.imgSrc}
                      title={service.title}
                    />
                  </div>
                ))}
              </Carousel>
            </div>
            {services.map((service, index) => (
              <div
                key={index}
                onClick={() => setActiveService(service)}
                className="service-card">
                <CardComponent imgSrc={service.imgSrc} title={service.title} />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default OurService;
