import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import Clinic from "../../assets/16-9-24/image 1.png";
import { Link } from "react-router-dom";

const ProductSlide1 = () => {
  return (
    <div className="product-slide">
      <div className="product-info">
        <p className="headline">Our Products</p>
        <h3>E Commerce System</h3>
        <p className="detail-info">
          E-commerce refers to the buying and selling of goods or services over
          the internet. It involves the transaction of money, data, and services
          between buyers and sellers through E-commerce, a wide range of
          activities, including online retail , B2B, B2C , payment and supply
          chain management. Business-to-Business (B2B) e-commerce refers to the
          online exchange of goods, services, or information between businesses.
          Business-to-Consumer (B2C) e-commerce, where businesses sell products
          or services directly to consumers, B2B e-commerce involves
          transactions between businesses, such as manufacturers, wholesalers,
          distributors, and retailers..
        </p>
        <Link to="/ecommerce">
          <p className="read-more headline" type="text">
            Read More <ArrowRightOutlined />
          </p>
        </Link>
      </div>
      <img
        className="clinic-img"
        src={Clinic}
        style={{ scale: "1.5" }}
        alt="clinic-management"
      />
    </div>
  );
};

export default ProductSlide1;
