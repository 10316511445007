import React from "react";
import SectionHeader from "../cards/Header";

const ApplicationHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Application Development Services"
        info="Application development can be tailored to various platforms and technologies, including web applications, mobile apps (iOS, Android), desktop applications, and hybrid or cross-platform applications. Customized Application development refers to the process of creating software applications for specific purposes, such as business productivity, communication, entertainment, education, or gaming, etc., Customized application development involves creating software solutions specifically tailored to meet the unique needs and requirements of a particular client or business. OBS- ensures that the software aligns perfectly with the client's operational processes, goals, challenges and providing a personalized solution, creating tailored software solutions to meet the unique needs and specifications of clients."
      />
    </div>
  );
};

export default ApplicationHeader;
