import { CareerHeader, JobPosts } from "../components";
import { Helmet } from "react-helmet";
const Careers = () => {
  return (
    <>
      <Helmet>
        <title>Careers | Origin Business Solutions</title>
      </Helmet>
      <CareerHeader />
      <JobPosts />
    </>
  );
};
export default Careers;
