import React from "react";
import "./index.css";

const RequestDemo = () => {
  return (
    <div className="request-demo-bg">
      <h3>Our Dedicated Team Is Here To Provide You</h3>
      <p>
        Requesting a demo is the perfect way to explore the features, benefits,
        and possibilities that our solution offers.
      </p>
      <div className="demo-input-container">
        <input
          type="email"
          placeholder="Enter Your Email"
          className="email-input pl-5 w-full"
        />
        <button className="request-demo-btn m-1">Request Demo</button>
      </div>
      <p>
        By clicking Sign Up you're confirming that you agree with our Terms and
        Conditions.
      </p>
    </div>
  );
};

export default RequestDemo;
