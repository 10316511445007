import React from "react";
// import FeatureCard from "../cards/FeatureCard";
import "./index.css";
import { first, second, third } from "../../assets/aboutIcons";
import AboutUsCard from "../cards/AboutUsCard";

const Vision = () => {
  return (
    <div className="vision-cards-container">
      <AboutUsCard
        className="vision"
        number={first}
        title="Our Mission"
        info="To be a innovative leader in software development and digital transformation, driving innovation and setting new standards for excellence in technology services. We envision a future where businesses of all sizes leverage digital advancements to achieve sustainable growth and thrive in evolving digital landscape."
      />
      <AboutUsCard
        className="vision"
        title="Our Vision"
        number={second}
        info="Our mission at Origin Business Solutions Company is to empower businesses with innovative software solutions and transformative digital strategies. We strive to deliver cutting-edge technology that enhances operational efficiency, fosters growth, and drives lasting success for our clients."
      />
      <AboutUsCard
        className="vision"
        title="Our Passion"
        number={third}
        info="We are deeply driven by our passionate in technology innovation and constantly seeking new ways to create impactful solutions for society. Our team is passionate about developing advanced solutions challenges and delivering results that exceed expectations. Our enthusiasm to commit by helping businesses transform through digital solutions."
      />
    </div>
  );
};

export default Vision;
