import React from "react";
import {
  Approach,
  OutsourcingDetail,
  OutsourcingHeader,
  WhyWithUs,
} from "../components";
import { Helmet } from "react-helmet";
const SoftwareOutsourcing = () => {
  return (
    <div>
      <Helmet>
        <title>Software Outsourcing | Origin Business Solutions</title>
      </Helmet>
      <OutsourcingHeader />
      <Approach />
      <OutsourcingDetail />
      <WhyWithUs />
    </div>
  );
};

export default SoftwareOutsourcing;
