import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";
import Hotel_Management from "../../assets/16-9-24/image 2.png";
import { Link } from "react-router-dom";
const ProductSlide2 = () => {
  return (
    <div className="product-slide">
      <div className="product-info">
        <p className="headline">Our Products</p>
        <h3>Origin Payment Solutions</h3>
        <p className="detail-info">
          OBS Payment Solutions provides the tools you need to succeed in
          today's digital economy.
        </p>
        <Link to="/payment">
          <p className="read-more headline" type="text">
            Read More <ArrowRightOutlined />
          </p>
        </Link>
      </div>
      <img
        className="clinic-img carousel-img"
        src={Hotel_Management}
        alt="clinic-management"
        style={{ scale: "1.5" }}
      />
    </div>
  );
};

export default ProductSlide2;
