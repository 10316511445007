import React from "react";
import { Carousel } from "antd";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel CSS
// import { CiCircleFilled } from "@ant-design/icons";
import { BsCircleFill } from "react-icons/bs";

const BenefitForCus = () => {
  return (
    <div className="ml-16 mb-20">
      <h3 className="loyalty-card-title text-white text-xl">
        Benefits for Customers
      </h3>
      <ul>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Rewards and Incentives: (Earn Points, Exclusive Offers)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Enhanced Shopping Experience: (Personalization, Convenience)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>
            Feeling Valued and Recognized: (Membership Tiers, Exclusive Events)
          </p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Savings and Benefits: (Discounts, Free Products)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Ease of Use: (Mobile Access, No need for physical cards)</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>
            Engagement and Enjoyment: (Gamification, Community Participation)
          </p>
        </li>
      </ul>
    </div>
  );
};

const BenefitForBusiness = () => {
  return (
    <div className="ml-32 mb-20">
      <h3 className="loyalty-card-title text-white text-xl">
        Benefits for Businesses
      </h3>
      <ul className="feature-card-info text-white">
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Increased Customer Retention</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          Enhanced Customer Experience
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Valuable Customer Insights</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Increased Sales and Revenue</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Brand Loyalty and Advocacy</p>
        </li>
        <li className="flex flex-row justify-start gap-5 align-middle">
          <p>
            <BsCircleFill size={5} className="mt-3" />
          </p>
          <p>Cost-Effective Marketing</p>
        </li>
      </ul>
    </div>
  );
};

const Benefits = () => {
  return (
    <div className="loyalty-benefit-container">
      <div className="w-1/2 mt-40 h-auto mr-0 ml-auto">
        <Carousel
          showThumbs={true}
          showStatus={false}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          transitionTime={5}>
          <div className="w-1/2 text-white">
            <BenefitForCus />
          </div>
          <div>
            <BenefitForBusiness />
          </div>
        </Carousel>
      </div>
    </div>
  );
};

export default Benefits;
