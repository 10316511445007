import React from "react";
import SolutionAllinOne from "../../assets/solutionAllinOne.png";
import SolutionCustomized from "../../assets/solutionCustomized.png";
import "./index.css";
import FeatureCard from "../cards/TenantCard";
import { motion } from "framer-motion";
import { one, two, three } from "../../assets/number";

const Solution = () => {
  return (
    <>
      <div className="solution-card-container">
        <motion.img
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          viewport={{ once: false, amount: 0.5 }}
          src={SolutionAllinOne}
          alt="solution-all-in-one"
        />
        <motion.img
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 200 }}
          viewport={{ once: false, amount: 0.5 }}
          src={SolutionCustomized}
          alt="solution-customized"
        />
      </div>

      <h3 className="payment-header">Multiple Payment Methods and Benefits</h3>
      <p className="payment-desc">
        Supports credit and debit cards, bank transfers, digital wallets, and
        mobile payments.Integrations with popular payment platforms like Mobile
        Wallets ( KBZ pay, AYA pay, Wave Pay ) , Mobile Banking ( MPU, Visa ,
        Master card, etc .,)
      </p>
      <div className="payment-cards">
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          viewport={{ once: false, amount: 0.5 }}>
          {" "}
          <FeatureCard
            number={one}
            title="Scalability"
            info="OBS designed to grow with your business, handling increased transaction volumes without compromising on performance."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 300 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 300 }}
          viewport={{ once: false, amount: 0.5 }}>
          {" "}
          <FeatureCard
            number={two}
            title="Cost-Effective"
            info="Competitive transaction fees and no hidden costs ensure you get the best value for your investment."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 100 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 100 }}
          viewport={{ once: false, amount: 0.5 }}>
          {" "}
          <FeatureCard
            number={three}
            title="Customer Trust"
            info="Enhanced security measures build customer confidence, leading to higher conversion rates and customer retention."
          />
        </motion.div>{" "}
      </div>
    </>
  );
};

export default Solution;
