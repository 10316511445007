import React from "react";
import { ApplicationHeader, Journey } from "../components";
import { Helmet } from "react-helmet";
const ApplicationDevelopment = () => {
  return (
    <div>
      <Helmet>
        <title>Application Development | Origin Business Solutions</title>
      </Helmet>
      <ApplicationHeader />
      <Journey />
      {/* <Packages/> */}
    </div>
  );
};

export default ApplicationDevelopment;
