import React from "react";
import { ArrowRightOutlined } from "@ant-design/icons";
import paymentImg from "../../assets/16-9-24/Payment Solution.png";
import "./index.css";
// import { Button } from "antd";

const PaymentSolution = () => {
  return (
    <div className="payment-solution pl-28 pr-28">
      <div>
        <div className="payment-solution-image-container">
          <img
            src={paymentImg}
            alt="payment-img"
            className="payment-solution-image"
          />
        </div>
      </div>
      <div className="payment-solution-info">
        <h3>OBS's Payment Solution</h3>
        <p>Connecting OBS's Payment Solution for simplifying transactions</p>
        <span className="read-more" type="text">
          Read More <ArrowRightOutlined />
        </span>
      </div>
    </div>
  );
};

export default PaymentSolution;
