import React from "react";
import { ECommerceHeader, Sitemap, Cards } from "../components";
import { Helmet } from "react-helmet";
const ObsEcommerce = () => {
  return (
    <div>
      <Helmet>
        <title>OBS E-commerce | Origin Business Solutions</title>
      </Helmet>
      <ECommerceHeader />
      <Sitemap />
      <Cards />
    </div>
  );
};

export default ObsEcommerce;
