import React from "react";
import {
  KeyComponents,
  LoyaltyBenefits,
  LoyaltyDev,
  LoyaltyHeader,
} from "../components";
import { Helmet } from "react-helmet";
const Loyalty = () => {
  return (
    <div>
      <Helmet>
        <title>
          OBS Loyalty and Menbership System | Origin Business Solutions
        </title>
      </Helmet>
      <LoyaltyHeader />
      <KeyComponents />
      <LoyaltyBenefits />
      <LoyaltyDev />
    </div>
  );
};

export default Loyalty;
