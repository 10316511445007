import React from 'react'
import SectionHeader from '../cards/Header'

const LoyaltyHeader = () => {
  return (
    <div>
      <SectionHeader title="Origin Loyalty and Membership System" info="A loyalty and membership application software is a digital solution that enables businesses to design, implement, and manage customer loyalty programs and membership systems. OBS’s Loyalty and Membership system provides tools for engaging customers, tracking their activities, rewarding their loyalty, and analyzing customer behavior to refine marketing strategies. By implementing a comprehensive loyalty and membership application software, businesses can effectively manage their loyalty programs, drive customer engagement, and achieve long-term growth." />
    </div>
  )
}

export default LoyaltyHeader