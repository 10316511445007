import React from 'react'
import { Layout } from 'antd'
import { Outlet } from 'react-router-dom';

const { Content } = Layout;

const App = () => {
  return (
    <div>
      <Content>
        <Outlet/>
      </Content>
    </div>
  )
}

export default App