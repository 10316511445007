import { SolutionsHeader } from "../components";
import { Helmet } from "react-helmet";
export default function Solutions() {
  return (
    <>
      <Helmet>
        <title>Solutions | Origin Business Solutions</title>
      </Helmet>
      <SolutionsHeader />;
    </>
  );
}
