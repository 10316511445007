import React from "react";
import FeatureCard from "../cards/TenantCard";
import { five, four, one, six, three, two } from "../../assets/number";

const Benefits = () => {
  return (
    <div className="benefits">
      <h3 className="benefits-title">
        Benefits of Origin Property -Tenant Management
      </h3>
      <p className="benefits-desc">
        We connects top-tier development talent with your vision to create
        solutions that become an extension of your business.
      </p>
      <div className="tenant-cards-container">
        <FeatureCard
          number={one}
          title="Efficiency"
          info="Automates routine tasks, reducing the time and effort required for property management."
        />
        <FeatureCard
          number={two}
          title="Accuracy"
          info="Minimizes errors in rent collection, lease management, and financial reporting."
        />
        <FeatureCard
          number={three}
          title="Improved Communication"
          info="Enhances communication with tenants, leading to better tenant satisfaction and retention."
        />
        <FeatureCard
          number={four}
          title="Financial Insight"
          info="Provides detailed financial reports and analytics to help property managers make informed decisions."
        />
        <FeatureCard
          number={five}
          title="Compliance"
          info="Helps ensure compliance with local laws and regulations through automated reminders and tracking."
        />
        <FeatureCard
          number={six}
          title="Scalability"
          info="Suitable for managing single or multiple properties, allowing the system to grow with your business."
        />
      </div>
    </div>
  );
};

export default Benefits;
