import React from 'react'
import '../obsEcommerce/index.css'

const EcommerceCard = ({imgSrc, title, desc}) => {
  return (
    <div className='ecommerce-card'>
      <img className='ecommerce-card-img' src={imgSrc} alt={imgSrc}/>
      <h3 className='ecommerce-card-title'>{title}</h3>
      <p className='ecommerce-card-desc'>{desc}</p>
    </div>
  )
}

export default EcommerceCard