import React from "react";
import { MainLayout } from "./layout";
import { Routes, Route } from "react-router-dom";
import {
  Hero,
  WebDevelopment,
  ApplicationDevelopment,
  SoftwareDevelopment,
  SoftwareOutsourcing,
  CloutHosting,
  AboutUs,
  ContactUs,
  ObsEcommerce,
  Tenant,
  Loyalty,
  Payment,
} from "./pages";
import Solutions from "./pages/Solution";
import Careers from "./pages/Careers";
import { JobPage } from "./components";

const App = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Hero />} />
        <Route path="web-development" element={<WebDevelopment />} />
        <Route
          path="application-development"
          element={<ApplicationDevelopment />}
        />
        <Route path="software-development" element={<SoftwareDevelopment />} />
        <Route path="software-outsourcing" element={<SoftwareOutsourcing />} />
        <Route path="cloud-hosting" element={<CloutHosting />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="ecommerce" element={<ObsEcommerce />} />
        <Route path="tenant" element={<Tenant />} />
        <Route path="loyalty" element={<Loyalty />} />
        <Route path="payment" element={<Payment />} />
        <Route path="solutions" element={<Solutions />} />
        <Route path="career" element={<Careers />} />
        <Route path="career/:position" element={<JobPage />} />
      </Route>
    </Routes>
  );
};

export default App;
