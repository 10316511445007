import React from "react";
import { CloudHostingHeader, CloudHostingProcess } from "../components";
import { Helmet } from "react-helmet";
const CloutHosting = () => {
  return (
    <div>
      <Helmet>
        <title> Cloud Hosting | Origin Business Solutions</title>
      </Helmet>
      <CloudHostingHeader />
      <CloudHostingProcess />
    </div>
  );
};

export default CloutHosting;
