import React from "react";
const AboutUsCard = ({ title, info, number }) => {
  return (
    <div className="h-full">
      <img
        src={number}
        alt="number-image"
        className="translate-y-8 ml-5 mb-2 w-20 "
      />

      <div className="about-card p-5">
        <p className="about-card-title text-xl mb-3 mt-3 text-white text-left">
          {title}
        </p>
        <p className="feature-card-info text-sm">{info}</p>
      </div>
    </div>
  );
};

export default AboutUsCard;
