import React from "react";
import SectionHeader from "../cards/Header";

const PaymentHeader = () => {
  return (
    <div>
      <SectionHeader
        title="Origin Payment Solutions"
        info="OBS Payment Solutions  provides the tools you need to succeed in today's digital economy"
      />
    </div>
  );
};

export default PaymentHeader;
