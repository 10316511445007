import React from "react";
import "./index.css";
import { ArrowRightOutlined } from "@ant-design/icons";

const HeroHeader = () => {
  return (
    <div className="hero-section-header">
      <p>Evaluate your digital transformation, landscape with </p>
      <h3 className="hero-section-main">
        OBS's <span>Digital Solution !</span>
      </h3>
      <button className="explore-more-btn">
        Explore More <ArrowRightOutlined />
      </button>
    </div>
  );
};

export default HeroHeader;
