import React from "react";
const FeatureCard = ({ title, info, number }) => {
  return (
    <div className="h-full overflow-y-hidden">
      <img
        src={number}
        alt="number-image"
        className="translate-y-8 ml-5 mb-2 w-20 "
      />
      <div className="feature-card max-w-96 h-60 w-full">
        <p className="feature-card-title text-left">{title}</p>
        <p className="feature-card-info">{info}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
