import React from "react";
import "../webDevelopment/index.css";

const SectionHeader = ({ title, info }) => {
  const sentences = info
    ? info.split(". ").filter((sentence) => sentence.trim() !== "")
    : []; // Fallback to an empty array if info is undefined or not provided

  return (
    <div className="header">
      <h3>{title}</h3>
      {sentences.map((sentence, index) => (
        <p key={index}>{sentence}.</p>
      ))}
    </div>
  );
};

export default SectionHeader;
