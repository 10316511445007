import React from "react";
import BenefitCard from "../cards/BenefitCard";
import solution from "../../assets/solution 1.png";
import scalability from "../../assets/scalability (1) 1.png";
import integration from "../../assets/distributed 1.png";
import competitive from "../../assets/competitive 1.png";
import satisfaction from "../../assets/satisfaction 1.png";
import { motion } from "framer-motion";

const Benefits = () => {
  return (
    <div className="benefits-container">
      <h3>Benefits of Customized Software Development</h3>
      <div className="benefit-cards-group">
        <motion.div
          initial={{ opacity: 0, x: -100 }}
          whileInView={{ opacity: 1, x: 50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          viewport={{ once: false, amount: 0.5 }}>
          <BenefitCard
            imgSrc={solution}
            title="Tailored Solutions"
            desc="The software is designed to meet the specific needs and workflows of the client."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 0 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          viewport={{ once: false, amount: 0.5 }}>
          <BenefitCard
            imgSrc={scalability}
            title="Scalability"
            desc="Can be scaled and adapted as the business grows or changes."
          />
        </motion.div>
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: -50 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 200 }}
          viewport={{ once: false, amount: 0.5 }}>
          <BenefitCard
            imgSrc={integration}
            title="Integration"
            desc="Seamlessly integrates with existing systems and technologies."
          />
        </motion.div>{" "}
        <motion.div
          initial={{ opacity: 0, x: -200 }}
          whileInView={{ opacity: 1, x: 90 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: -200 }}
          viewport={{ once: false, amount: 0.5 }}>
          <BenefitCard
            imgSrc={competitive}
            title="Competitive Advantage"
            desc="Offers unique functionalities that can provide a competitive edge."
          />
        </motion.div>{" "}
        <motion.div
          initial={{ opacity: 0, x: 200 }}
          whileInView={{ opacity: 1, x: -90 }}
          transition={{ duration: 1.5, ease: "easeInOut" }}
          exit={{ opacity: 0, x: 200 }}
          viewport={{ once: false, amount: 0.5 }}>
          <BenefitCard
            imgSrc={satisfaction}
            title="User Satisfaction"
            desc="Ensures a better user experience as the software is designed with the end-users in mind."
          />
        </motion.div>
      </div>
    </div>
  );
};

export default Benefits;
